/*--------------------------------------------------------------------------*
 * mv
 *--------------------------------------------------------------------------*/
$(function() {
    $('#top-mv').slick({
        dots: true,
        // dots: false,
        fade: true,
        cssEase: 'linear',
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        lazyLoad: 'progressive'
    });
});
  

